import React from 'react';
import { I18NProvider } from '../components/I18NProvider';
import Main from '../components/main';

class IndexPage extends React.Component {
  render() {
    return (
      <I18NProvider>
        {lng => {
          return (
            <Main lng={lng} location={this.props.location}/>
          );
        }}
        
      </I18NProvider>
    );
  }
}

export default IndexPage;
